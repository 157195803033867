/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*<Button width="full" size="xl" colorScheme="gray">XLarge</Button>*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    h3: "h3",
    h1: "h1",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    ul: "ul",
    li: "li",
    ol: "ol",
    strong: "strong"
  }, _provideComponents(), props.components), {Box, Container, Stack, Heading, Wrap, Button} = _components;
  if (!Box) _missingMdxReference("Box", true);
  if (!Button) _missingMdxReference("Button", true);
  if (!Container) _missingMdxReference("Container", true);
  if (!Heading) _missingMdxReference("Heading", true);
  if (!Stack) _missingMdxReference("Stack", true);
  if (!Wrap) _missingMdxReference("Wrap", true);
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    layerStyle: "pageTitle"
  }, React.createElement(Container, {
    maxW: "container.xl"
  }, React.createElement(Stack, {
    align: "start",
    direction: ["column", "column", "column"]
  }, React.createElement(Heading, {
    as: "h1",
    size: "xl"
  }, "Style guide"), React.createElement(_components.p, null, "This guide serves as a reference for all design and content creation, ensuring a cohesive and professional representation of your brand. By following the guidelines outlined in the Style Guide, your brand will have a consistent look and feel, making it easily recognizable and trustworthy to your audience.")))), "\n", React.createElement(Container, {
    maxW: "container.xl",
    mb: [10, 14, 20]
  }, React.createElement(Stack, {
    spacing: [10, 14, 20],
    fontSize: "lg"
  }, React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Box, {
    flex: "1",
    maxW: ["full", "full", "300"],
    mb: [4, 4, 0],
    pb: [4, 4, 0],
    borderBottomWidth: ["1px", "1px", 0]
  }, React.createElement(_components.h2, {
    id: "colors"
  }, React.createElement(_components.a, {
    href: "#colors"
  }, "Colors"))), React.createElement(Stack, {
    spacing: 4,
    flex: "3"
  }, React.createElement(_components.h3, {
    id: "brand"
  }, React.createElement(_components.a, {
    href: "#brand"
  }, "Brand")), React.createElement(Wrap, {
    spacing: 1
  }, React.createElement(Box, {
    bg: "brand.50",
    color: "blackAlpha.800",
    layerStyle: "colorBox"
  }, "50"), "\n", React.createElement(Box, {
    bg: "brand.100",
    layerStyle: "colorBox"
  }, "100"), "\n", React.createElement(Box, {
    bg: "brand.200",
    layerStyle: "colorBox"
  }, "200"), "\n", React.createElement(Box, {
    bg: "brand.300",
    layerStyle: "colorBox"
  }, "300"), "\n", React.createElement(Box, {
    bg: "brand.400",
    layerStyle: "colorBox"
  }, "400"), "\n", React.createElement(Box, {
    bg: "brand.500",
    layerStyle: "colorBox"
  }, "500"), "\n", React.createElement(Box, {
    bg: "brand.600",
    layerStyle: "colorBox"
  }, "600"), "\n", React.createElement(Box, {
    bg: "brand.700",
    layerStyle: "colorBox"
  }, "700"), "\n", React.createElement(Box, {
    bg: "brand.800",
    layerStyle: "colorBox"
  }, "800"), "\n", React.createElement(Box, {
    bg: "brand.900",
    layerStyle: "colorBox"
  }, "900")), React.createElement(_components.h3, {
    id: "primary"
  }, React.createElement(_components.a, {
    href: "#primary"
  }, "Primary")), React.createElement(Wrap, {
    spacing: 1
  }, React.createElement(Box, {
    bg: "primary.50",
    color: "blackAlpha.800",
    layerStyle: "colorBox"
  }, "50"), "\n", React.createElement(Box, {
    bg: "primary.100",
    layerStyle: "colorBox"
  }, "100"), "\n", React.createElement(Box, {
    bg: "primary.200",
    layerStyle: "colorBox"
  }, "200"), "\n", React.createElement(Box, {
    bg: "primary.300",
    layerStyle: "colorBox"
  }, "300"), "\n", React.createElement(Box, {
    bg: "primary.400",
    layerStyle: "colorBox"
  }, "400"), "\n", React.createElement(Box, {
    bg: "primary.500",
    layerStyle: "colorBox"
  }, "500"), "\n", React.createElement(Box, {
    bg: "primary.600",
    layerStyle: "colorBox"
  }, "600"), "\n", React.createElement(Box, {
    bg: "primary.700",
    layerStyle: "colorBox"
  }, "700"), "\n", React.createElement(Box, {
    bg: "primary.800",
    layerStyle: "colorBox"
  }, "800"), "\n", React.createElement(Box, {
    bg: "primary.900",
    layerStyle: "colorBox"
  }, "900")), React.createElement(_components.h3, {
    id: "secondary"
  }, React.createElement(_components.a, {
    href: "#secondary"
  }, "Secondary")), React.createElement(Wrap, {
    spacing: 1
  }, React.createElement(Box, {
    bg: "secondary.50",
    color: "blackAlpha.800",
    layerStyle: "colorBox"
  }, "50"), "\n", React.createElement(Box, {
    bg: "secondary.100",
    layerStyle: "colorBox"
  }, "100"), "\n", React.createElement(Box, {
    bg: "secondary.200",
    layerStyle: "colorBox"
  }, "200"), "\n", React.createElement(Box, {
    bg: "secondary.300",
    layerStyle: "colorBox"
  }, "300"), "\n", React.createElement(Box, {
    bg: "secondary.400",
    layerStyle: "colorBox"
  }, "400"), "\n", React.createElement(Box, {
    bg: "secondary.500",
    layerStyle: "colorBox"
  }, "500"), "\n", React.createElement(Box, {
    bg: "secondary.600",
    layerStyle: "colorBox"
  }, "600"), "\n", React.createElement(Box, {
    bg: "secondary.700",
    layerStyle: "colorBox"
  }, "700"), "\n", React.createElement(Box, {
    bg: "secondary.800",
    layerStyle: "colorBox"
  }, "800"), "\n", React.createElement(Box, {
    bg: "secondary.900",
    layerStyle: "colorBox"
  }, "900")), React.createElement(_components.h3, {
    id: "natural"
  }, React.createElement(_components.a, {
    href: "#natural"
  }, "Natural")), React.createElement(Wrap, {
    overlay: "visible",
    spacing: 1
  }, React.createElement(Box, {
    borderWidth: "1px",
    borderColor: "gray.200",
    bg: "white",
    color: "blackAlpha.800",
    layerStyle: "colorBox"
  }, "white"), "\n", React.createElement(Box, {
    bg: "gray.100",
    layerStyle: "colorBox"
  }, "100"), "\n", React.createElement(Box, {
    bg: "gray.200",
    layerStyle: "colorBox"
  }, "200"), "\n", React.createElement(Box, {
    bg: "gray.300",
    layerStyle: "colorBox"
  }, "300"), "\n", React.createElement(Box, {
    bg: "gray.400",
    layerStyle: "colorBox"
  }, "400"), "\n", React.createElement(Box, {
    bg: "gray.500",
    layerStyle: "colorBox"
  }, "500"), "\n", React.createElement(Box, {
    bg: "gray.600",
    layerStyle: "colorBox"
  }, "600"), "\n", React.createElement(Box, {
    bg: "gray.700",
    layerStyle: "colorBox"
  }, "700"), "\n", React.createElement(Box, {
    bg: "gray.800",
    layerStyle: "colorBox"
  }, "800"), "\n", React.createElement(Box, {
    bg: "gray.900",
    layerStyle: "colorBox"
  }, "900")))), React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Box, {
    flex: "1",
    maxW: ["full", "full", "300"],
    mb: [4, 4, 0],
    pb: [4, 4, 0],
    borderBottomWidth: ["1px", "1px", 0]
  }, React.createElement(_components.h2, {
    id: "typography"
  }, React.createElement(_components.a, {
    href: "#typography"
  }, "Typography"))), React.createElement(Stack, {
    spacing: 4,
    flex: "3"
  }, React.createElement(_components.h3, {
    id: "heading"
  }, React.createElement(_components.a, {
    href: "#heading"
  }, "Heading")), React.createElement(Wrap, {
    align: "baseline",
    spacing: 4
  }, React.createElement(Heading, {
    size: "4xl"
  }, "4XL"), "\n", React.createElement(Heading, {
    size: "3xl"
  }, "3XL"), "\n", React.createElement(Heading, {
    size: "2xl"
  }, "2XL"), React.createElement(_components.h1, {
    id: "h1"
  }, React.createElement(_components.a, {
    href: "#h1"
  }, "H1")), React.createElement(_components.h2, {
    id: "h2"
  }, React.createElement(_components.a, {
    href: "#h2"
  }, "H2")), React.createElement(_components.h3, {
    id: "h3"
  }, React.createElement(_components.a, {
    href: "#h3"
  }, "H3")), React.createElement(_components.h4, {
    id: "h4"
  }, React.createElement(_components.a, {
    href: "#h4"
  }, "H4")), React.createElement(_components.h5, {
    id: "h5"
  }, React.createElement(_components.a, {
    href: "#h5"
  }, "H5")), React.createElement(_components.h6, {
    id: "h6"
  }, React.createElement(_components.a, {
    href: "#h6"
  }, "H6"))), React.createElement(_components.h3, {
    id: "paragraph"
  }, React.createElement(_components.a, {
    href: "#paragraph"
  }, "Paragraph")), React.createElement(_components.p, null, "Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Quisque placerat facilisis egestas cillum dolore. Magna pars studiorum, prodita quaerimus. Morbi fringilla convallis sapien, id pulvinar odio volutpat. Paullum deliquit, ponderibus modulisque suis ratio utitur."))), React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Box, {
    flex: "1",
    maxW: ["full", "full", "300"],
    mb: [4, 4, 0],
    pb: [4, 4, 0],
    borderBottomWidth: ["1px", "1px", 0]
  }, React.createElement(_components.h2, {
    id: "list"
  }, React.createElement(_components.a, {
    href: "#list"
  }, "List"))), React.createElement(Stack, {
    spacing: 4,
    flex: "3"
  }, React.createElement(_components.h3, {
    id: "unorderlist"
  }, React.createElement(_components.a, {
    href: "#unorderlist"
  }, "Unorderlist")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Streamline your workflow with multi-level parallelism."), "\n", React.createElement(_components.li, null, "Maximize efficiency with high-performing concurrent solutions."), "\n", React.createElement(_components.li, null, "Stay driven and productive with task-focused solutions."), "\n"), React.createElement(_components.h3, {
    id: "orderlist"
  }, React.createElement(_components.a, {
    href: "#orderlist"
  }, "Orderlist")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Streamline your workflow with multi-level parallelism."), "\n", React.createElement(_components.li, null, "Maximize efficiency with high-performing concurrent solutions."), "\n", React.createElement(_components.li, null, "Stay driven and productive with task-focused solutions."), "\n"))), React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Box, {
    flex: "1",
    maxW: ["full", "full", "300"],
    mb: [4, 4, 0],
    pb: [4, 4, 0],
    borderBottomWidth: ["1px", "1px", 0]
  }, React.createElement(_components.h2, {
    id: "button"
  }, React.createElement(_components.a, {
    href: "#button"
  }, "Button"))), React.createElement(Stack, {
    spacing: 4,
    flex: "3"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Solid")), React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Button, {
    width: "full",
    colorScheme: "brand"
  }, "Brand"), "\n", React.createElement(Button, {
    width: "full",
    colorScheme: "primary"
  }, "Primary"), "\n", React.createElement(Button, {
    width: "full",
    colorScheme: "secondary"
  }, "Secondary"), "\n", React.createElement(Button, {
    width: "full",
    colorScheme: "white"
  }, "White"), "\n", React.createElement(Button, {
    width: "full",
    colorScheme: "gray"
  }, "Default"), "\n", React.createElement(Button, {
    width: "full",
    colorScheme: "black"
  }, "Black")), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Outline")), React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Button, {
    width: "full",
    variant: "outline",
    colorScheme: "brand"
  }, "Brand"), "\n", React.createElement(Button, {
    width: "full",
    variant: "outline",
    colorScheme: "primary"
  }, "Primary"), "\n", React.createElement(Button, {
    width: "full",
    variant: "outline",
    colorScheme: "secondary"
  }, "Secondary"), "\n", React.createElement(Button, {
    width: "full",
    variant: "outline",
    colorScheme: "white"
  }, "White"), "\n", React.createElement(Button, {
    width: "full",
    variant: "outline",
    colorScheme: "gray"
  }, "Default"), "\n", React.createElement(Button, {
    width: "full",
    variant: "outline",
    colorScheme: "black"
  }, "Black")), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Ghost")), React.createElement(Stack, {
    direction: ["column", "column", "row"]
  }, React.createElement(Button, {
    width: "full",
    variant: "ghost",
    colorScheme: "brand"
  }, "Brand"), "\n", React.createElement(Button, {
    width: "full",
    variant: "ghost",
    colorScheme: "primary"
  }, "Primary"), "\n", React.createElement(Button, {
    width: "full",
    variant: "ghost",
    colorScheme: "secondary"
  }, "Secondary"), "\n", React.createElement(Button, {
    width: "full",
    variant: "ghost",
    colorScheme: "white"
  }, "White"), "\n", React.createElement(Button, {
    width: "full",
    variant: "ghost",
    colorScheme: "gray"
  }, "Default"), "\n", React.createElement(Button, {
    width: "full",
    variant: "ghost",
    colorScheme: "black"
  }, "Black")), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Sizes")), React.createElement(Stack, {
    align: "center",
    direction: ["column", "column", "row"]
  }, React.createElement(Button, {
    width: "full",
    size: "xs",
    colorScheme: "brand"
  }, "XSmall"), "\n", React.createElement(Button, {
    width: "full",
    size: "sm",
    colorScheme: "primary"
  }, "Small"), "\n", React.createElement(Button, {
    width: "full",
    size: "md",
    colorScheme: "secondary"
  }, "Normal"), "\n", React.createElement(Button, {
    width: "full",
    size: "lg",
    colorScheme: "gray"
  }, "Large"), React.createElement(Button, {
    width: "full",
    size: "jumbo",
    colorScheme: "black"
  }, "Jumbo")))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
